import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { deleteTestimonial, markcontactRequest } from '../utlity/fetch';
import { useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};
const Testimonialtable = ({ data }) => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [deatil, setdetail] = React.useState(false);
    const handleOpen = (data) => {
        setOpen(true);
        setdetail(data);
    }
    const handleClose = () => setOpen(false);

    const changepara = (id) => {
        alert(id)
        markcontactRequest(id).then(e => {
            console.log(e
            )
        }).catch(err => {
            console.log(err)
            navigate("/")
        })
    }
    const handleDelete = (id) => {
        const asking = window.confirm("Are you sure! this action is not reverseable")
        if (asking) {
            deleteTestimonial(id).then((data) => {
                alert("Lead Deleted")
                handleClose()
                window.location.reload()
            }).catch((err) => {
                handleClose()
            })
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Name : {deatil.name}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Position : {deatil.position}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Company : {deatil.company}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Message : <br />
                        {deatil.message}
                    </Typography>
                    <button onClick={e => handleDelete(deatil._id)} className='bg-red-700 px-2 py-2 rounded-lg text-white' >Delete Lead</button>
                </Box>
            </Modal>
            {/* head */}
            <div className="flex border px-4 py-2 bg-gray-200">
                <div className="flex-1">Id</div>
                <div className="flex-1">Name</div>
                <div className="flex-1">Position</div>
                <div className="flex-1">Company</div>
            </div>
            {/* Row */}
            {data && !data.error && data.map((e, index) => {
                return (
                    <div className="flex border px-4 py-2 cursor-pointer " key={index} onClick={pow => handleOpen(e)} >
                        <div className="flex-1">{e._id}</div>
                        <div className="flex-1">{e.name}</div>
                        <div className="flex-1">{e.position}</div>
                        <div className="flex-1">{e.company}</div>
                    </div>
                )
            })}


        </div>
    )
}

export default Testimonialtable
