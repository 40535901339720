import logo from './logo.svg';
import './App.css';
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import { AlertDialog } from '@radix-ui/themes';
import { Button, Flex } from '@radix-ui/themes';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './layout/Dashboard';
import Testimonial from './pages/Testimonial';
import Login from './pages/Login';
import Lead from "./pages/Lead";
import User from "./pages/User"
import PagenotFound from './PagenotFound';
function App() {
  return (
    <BrowserRouter>
      <Routes>

        {/* Dashboard */}
        <Route path='/dashboard' element={<Dashboard />} >
          <Route path='lead' element={<Lead />} />
          <Route path='testimonial' element={<Testimonial />} />
          <Route path='user-management' element={<User />} />
        </Route>

        {/* login */}
        <Route path='/' element={<Login />} />
        <Route path='*' element={<PagenotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
