import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createuserRequest, loginRequest } from '../utlity/fetch';
import CircularProgress from '@mui/material/CircularProgress';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
};

export default function Navbar() {


    const [open, setOpen] = React.useState(false);
    const [loader, setloader] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            password: '',
            email: '',
        },
        validationSchema:
            Yup.object().shape({
                name: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Required'),
                password: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Required'),
                email: Yup.string().email('Invalid email').required('Required'),
            }),
        onSubmit: values => {
            setloader(true)
            createuserRequest(values.name, values.email, values.password).then(data => {
                alert("User created")
                setloader(false)
                handleClose()
            }).catch(err => {
                alert(err)
            })
        },
    });
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formik.handleSubmit}>

                    <Box sx={style} className="flex-col gap-2" >
                        <p className='text-center text-2xl' >Enter Details</p>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            className='w-full h-10 border px-2 border-gray-600 rounded-md'
                            placeholder='Enter Name'
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <p className="text-red-500">{formik.errors.name}</p>
                        ) : null}

                        <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className='w-full h-10 border px-2 border-gray-600 rounded-md'
                            placeholder='Enter Email'
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <p className="text-red-500">{formik.errors.email}</p>
                        ) : null}

                        <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className='w-full h-10 border px-2 border-gray-600 rounded-md'
                            placeholder='Enter Password'
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <p className="text-red-500">{formik.errors.password}</p>
                        ) : null}
                        <div className="flex gap-2">
                            <button className='w-full text-lg border py-2 rounded-lg border-blue-400 bg-blue-400 text-white' >{loader ? <CircularProgress color="success" /> : "Create"}</button>
                        </div>

                    </Box>
                </form>
            </Modal>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Celestnova
                    </Typography>
                    <Button color="inherit" onClick={handleOpen} >Create user</Button>
                </Toolbar>
            </AppBar>
        </Box >
    );
}
