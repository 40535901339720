import React, { useState } from 'react'
import { Button } from '@radix-ui/themes'
import logo from "../assets/logo.png"
import { useFormik } from 'formik'
import * as Yup from "yup"
import { loginRequest } from '../utlity/fetch'
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const navigate = useNavigate();
    // const fetchdata = (e => {
    //     e.preventDefault()
    //     getcontactRequest(false).then(data => {
    //         console.log(data)
    //     }).catch(err => {
    //         console.log(err)
            // navigate("/")
    //     })
    // })
    const [isloading, setisloading] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            pwd: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            pwd: Yup.string()
                .min(6, 'Password should be at least 6 characters')
                .required('Password is required'),
        }),
        onSubmit: values => {
            setisloading(true)
            loginRequest(values.email, values.pwd).then(e => {
                setisloading(false)
                console.log(e.data.token)
                if (e.data?.token) {
                    navigate('/dashboard/lead')
                } else {
                    alert(e.data.error)
                }
            }).catch(err => {
                setisloading(false)
                alert(err)
            })
        },
    });
    React.useEffect(() => {
        
        if (!localStorage.getItem("token")) {
            navigate("/")
        }
    }, []);
    return (

        <div className="relative py-16">
            <div className="container relative m-auto px-6 text-gray-500 md:px-12 xl:px-40">
                <div className="m-auto space-y-8 md:w-8/12 lg:w-6/12 xl:w-6/12">
                    <img src={logo} alt="" />
                    <div className="rounded-3xl border  bg-gray-700 border-gray-700 shadow-2xl shadow-gray-600/10 backdrop-blur-2xl">
                        <div className="p-8 py-12 sm:p-16">
                            <h2 className="mb-8 text-2xl font-bold  text-white">Sign in to your account</h2>
                            <form onSubmit={formik.handleSubmit} className="space-y-8">
                                <div className="space-y-2">
                                    <label htmlFor="email" className=" text-gray-300">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="username"
                                        className="focus:outline-none block w-full rounded-md border  border-gray-600 bg-transparent px-4 py-3 text-white transition duration-300 invalid:ring-2 invalid:ring-red-400 focus:ring-2 focus:ring-cyan-300"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <p className="text-red-500 text-sm">{formik.errors.email}</p>
                                    ) : null}
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="pwd" className=" text-gray-300">Password</label>
                                    <input
                                        type="password"
                                        name="pwd"
                                        id="pwd"
                                        autoComplete="current-password"
                                        className="focus:outline-none block w-full rounded-md border  border-gray-600 bg-transparent px-4 py-3 text-white transition duration-300 invalid:ring-2 invalid:ring-red-400 focus:ring-2 focus:ring-cyan-300"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.pwd}
                                    />
                                    {formik.touched.pwd && formik.errors.pwd ? (
                                        <p className="text-red-500 text-sm">{formik.errors.pwd}</p>
                                    ) : null}
                                </div>

                                {isloading ? <div className="flex w-full items-center justify-center ">
                                    <div className="loader"></div>
                                </div> : <button
                                    type="submit"
                                    className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                >
                                    <span className="relative text-base font-semibold text-white text-dark">Connect</span>
                                </button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login
