import * as React from 'react';
import logo from "../assets/logo.png"
import { IoPower } from "react-icons/io5";
import { MdSupervisedUserCircle } from "react-icons/md";
import Navbar from "./Navbar"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from './Table';
import { getcontactRequest, logoutReq } from '../utlity/fetch';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { MdOutlineReviews } from "react-icons/md";
// import { useHistory } from 'react-router-dom';
const Dashboard = () => {
    const navigate = useNavigate()


    const [value, setValue] = React.useState('newlead');
    const [tabledata, settabledata] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    React.useEffect(() => {
        const iscon = value === 'newlead' ? false : true
        getcontactRequest(iscon).then((data) => {
            console.log(data.data)
            settabledata(data.data)
        }).catch(err => {
            console.log(err)
            navigate("/")
        })
    }, [value]);

    React.useEffect(() => {
        console.log(localStorage.getItem("token"))
        if (localStorage.getItem("token") === 'false' || localStorage.getItem("token") === null) {
            navigate("/")
        }
    }, []);

    const handleLogout = () => {
        logoutReq().then((data) => {
            navigate("/")
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <div className='flex items-center justify-center w-full h-screen'  >
            <div className=" flex-col w-2/12 h-full flex items-start">
                <div className="w-full">
                    <img src={logo} alt="logo" />
                </div>
                <div className="flex-grow px-2 py-2 flex flex-col gap-3  w-full">
                    <NavLink to={"/dashboard/lead"} className={({ isActive }) =>
                        `cursor-pointer w-full flex items-center gap-3 px-3 py-2 text-xl rounded-lg hover:text-black hover:bg-gray-200 ${isActive ? 'bg-blue-500 text-white' : 'text-gray-800'
                        }`
                    } >
                        <MdSupervisedUserCircle />
                        Lead</NavLink>
                    <NavLink
                        to={"/dashboard/testimonial"}
                        className={({ isActive }) =>
                            `cursor-pointer w-full flex items-center gap-3 px-3 py-2 text-xl rounded-lg hover:text-black hover:bg-gray-200 ${isActive ? 'bg-blue-500 text-white' : 'text-gray-800'
                            }`
                        } >
                        <MdOutlineReviews />
                        Testimonial</NavLink>
                    <NavLink
                        to={"/dashboard/user-management"}
                        className={({ isActive }) =>
                            `cursor-pointer w-full flex items-center gap-3 px-3 py-2 text-xl rounded-lg hover:text-black hover:bg-gray-200 ${isActive ? 'bg-blue-500 text-white' : 'text-gray-800'
                            }`
                        } >
                        <MdOutlineReviews />
                        User</NavLink>
                </div>
                <div className="w-full px-2 py-2">
                    <button onClick={handleLogout} className='w-full flex justify-start px-3  text-xl items-center gap-3 py-2 hover:bg-gray-200 rounded-lg' >
                        <IoPower />
                        Logout</button>
                </div>
            </div>
            <div className="border-l-2  w-10/12 h-full">
                <Outlet />
                {/* <Navbar />
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="wrapped label tabs example"
                    >
                        <Tab value="newlead" label="New lead" />
                        <Tab value="contacted" label="Contacted" />
                    </Tabs>
                </Box>
                <Table data={tabledata} /> */}
            </div>
        </div>
    )
}

export default Dashboard
