import React from 'react'
import Navbar from "../layout/Navbar"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '../layout/Table';
import { getcontactRequest } from '../utlity/fetch';
import BasicLineChart from '../utlity/Basicchart';
import { useNavigate } from 'react-router-dom';
const Lead = () => {
    const [value, setValue] = React.useState('newlead');
    const [tabledata, settabledata] = React.useState([]);

    const navigate = useNavigate()


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    React.useEffect(() => {
        const iscon = value === 'newlead' ? false : true
        getcontactRequest(iscon).then((data) => {
            console.log(data.data)
            settabledata(data.data)
        }).catch(err => {
            console.log(err)
            navigate("/")

        })
    }, [value]);
    React.useEffect(() => {
        
        if (localStorage.getItem("token") === 'false' || localStorage.getItem("token") === null) {
            navigate("/")
        }
    }, []);
    return (
        <React.Fragment>
            <Navbar />
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                >
                    <Tab value="newlead" label="New lead" />
                    <Tab value="contacted" label="Contacted" />
                </Tabs>
            </Box>
            <Table data={tabledata} />
            {/* <BasicLineChart /> */}
        </React.Fragment>
    )
}

export default Lead
