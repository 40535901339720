import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { changePassword, deleteUser, markcontactRequest } from '../utlity/fetch';
import { useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};
const Usertable = ({ data }) => {
    const [open, setOpen] = React.useState(false);
    const [deatil, setdetail] = React.useState(false);
    const handleOpen = (data) => {
        setOpen(true);
        setdetail(data);
    }
    const navigate = useNavigate()
    const handleClose = () => setOpen(false);

    const changepara = (id) => {
        alert(id)
        markcontactRequest(id).then(e => {
            console.log(e
            )
        }).catch(err => {
            console.log(err)
            navigate("/")
        })
    }
    const [newpassword, setnewpassword] = useState("")
    const changepasswordHandler = (email) => {
        if (newpassword === '') return alert("Please enter new password");
        changePassword(email, newpassword).then(e => {
            alert("Password changed successfully")
            window.location.reload()
        }).catch(err => {
            alert("Caught error", err)
        })

    }
    const deleteuserhanlder = (email) => {
        if (window.confirm("Are you sure to delete the account")) {
            deleteUser(email).then(e => {
                alert("Account deleted successfully")
                window.location.reload()
            }).catch(err => {
                alert("Caught error", err)
            })
        }
        else {
            window.location.reload()
        }
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <input type="text" onChange={e => setnewpassword(e.target.value)} value={newpassword} placeholder='Enter New Password' className='border-gray-700 w-full border rounded-lg px-2 py-2' />
                    <button className='w-full text-center bg-blue-400 rounded-lg py-2 mt-2' onClick={e => changepasswordHandler(deatil.email)} >Change Password</button>
                    <div className="w-full text-center my-3">OR</div>
                    <button className='bg-red-400 w-full text-center py-2 rounded-xl' onClick={e => deleteuserhanlder(deatil.email)} >Delete User</button>
                </Box>
            </Modal>
            {/* head */}
            <div className="flex border px-4 py-2 bg-gray-200">
                <div className="flex-1">Id</div>
                <div className="flex-1">Name</div>
                <div className="flex-1">Email</div>
            </div>
            {/* Row */}
            {data && !data.error && data.map((e, index) => {
                return (
                    <div className="flex border px-4 py-2 cursor-pointer " key={index} onClick={pow => handleOpen(e)} >
                        <div className="flex-1">{e._id}</div>
                        <div className="flex-1">{e.name}</div>
                        <div className="flex-1">{e.email}</div>
                    </div>
                )
            })}

        </div>
    )
}

export default Usertable
