import React from 'react'
import { Link } from 'react-router-dom'
const PagenotFound = () => {
    return (
        <div className='h-screen w-screen flex items-center justify-center flex-col gap-2' >
            <h1 className='text-[4xl]' >404</h1>
            <h1 className='text-[4xl]' >Page not Found</h1>
            <Link to={"/"} className='text-blue-600' >Go to Login</Link>
        </div>
    )
}

export default PagenotFound
