import React from 'react'
import Navbar from "../layout/Navbar"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Usertable from '../layout/Usertable';
import { listUsers } from '../utlity/fetch';
import { useNavigate } from 'react-router-dom';
const User = () => {
    const [value, setValue] = React.useState('all');
    const [tabledata, settabledata] = React.useState([]);
    const navigate = useNavigate()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    React.useEffect(() => {
        listUsers().then((data) => {
            console.log(data.users)
            settabledata(data.users)
        }).catch(err => {
            console.log(err)
            navigate("/")
        })
    }, [value]);
    React.useEffect(() => {
        
        if (localStorage.getItem("token") === 'false' || localStorage.getItem("token") === null) {
            navigate("/")
        }
    }, []);
    return (
        <React.Fragment>
            <Navbar />
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                >
                    <Tab value="all" label="All" />
                </Tabs>
            </Box>
            <Usertable data={tabledata} />
        </React.Fragment>
    )
}

export default User
