import axios from "axios"

const backendURl = 'https://crm-celestnova.onrender.com'
// const backendURl = 'http://localhost:8000'


const apiRequest = (endpoint, method, data = null) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: method,
            url: `${backendURl}/${endpoint}`,
            withCredentials: true,
            data: data,
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                resolve(response.data);
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    });
};

const loginRequest = (email, password) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/login`, { email, password }, {
            withCredentials: true
        }).then(data => {
            console.log(data)
            resolve(data)
            localStorage.setItem("token", true)
        }).catch(err => {
            console.log(err)

            reject(err)
        })
    })

}
const createuserRequest = (name, email, password) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/createUser`, { name, email, password }, {
            withCredentials: true
        }).then(data => {
            console.log(data)
            resolve(data)
        }).catch(err => {
            console.log(err)

            reject(err)
        })
    })

}

const deleteUser = (email) => {
    return apiRequest('deleteUser', 'DELETE', { email });
};

const changePassword = (email, newPassword) => {
    return apiRequest('changePassword', 'POST', { email, newPassword });
};

const listUsers = () => {
    return apiRequest('listUsers', 'GET');
};

// Testimonial
const listTestimonials = () => {
    return apiRequest('listTestimonials', 'GET');
};


const getcontactRequest = (iscontacted) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/contact`, { isContacted: iscontacted }, {
            withCredentials: true
        }).then(data => {
            console.log(data)
            resolve(data)
        }).catch(err => {
            console.log(err)

            reject(err)
        })
    })

}
const markcontactRequest = (id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/updateContacted`, { id }, {
            withCredentials: true
        }).then(data => {
            console.log(data)
            resolve(data)
        }).catch(err => {
            console.log(err)

            reject(err)
        })
    })

}
const deleteTestimonial = (id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/deleteTestimonial`, { id }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
};
const deleteContact = (id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/deleteContact`, { id }, {
            withCredentials: true
        })
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
};
const logoutReq = () => {
    localStorage.setItem("token", false)
    return new Promise((resolve, reject) => {
        axios.post(`${backendURl}/logout`, {}, {
            withCredentials: true // Ensure cookies are sent with the request
        })
            .then(response => {
                console.log(response);
                resolve(response);
                

            })
            .catch(error => {
                console.log(error);
                reject(error);

            });
    });
};





export { loginRequest, createuserRequest, listUsers, changePassword, deleteUser, getcontactRequest, markcontactRequest, listTestimonials, deleteTestimonial, deleteContact, logoutReq }